
const convertDate = (strDt) => {
  if (strDt === undefined || strDt === null || strDt === '') {
    return ''
  }
  const month = {
    K01: 'JAN',
    K02: 'FEB',
    K03: 'MAR',
    K04: 'APR',
    K05: 'MAY',
    K06: 'JUN',
    K07: 'JUL',
    K08: 'AUG',
    K09: 'SEP',
    K10: 'OCT',
    K11: 'NOV',
    K12: 'DEC'
  }
  const key = 'K' + strDt.substring(4, 6)
  const m = month[key] || ''
  const d = strDt.substring(6, 8)
  const t = strDt.substring(8, 10)
  return `${m} ${d} ${t}H`
}

const convertDateYmd = (strDt) => {
  if (strDt === undefined || strDt === null || strDt === '' || strDt.length < 8) {
    return ''
  }
  const y = strDt.substring(0, 4)
  const m = strDt.substring(4, 6)
  const d = strDt.substring(6, 8)
  return `${y}.${m}.${d}`
}

// 컨테이너 정보 영역
const rendererCntrQty = (grid, cell) => {
  const idx = cell.index.dataRow
  const cntrQty = vmAppIm.gridList[idx].cntrQty
  const dgCertiCnt = vmAppIm.gridList[idx].dgCertiCnt
  const hzYn = vmAppIm.gridList[idx].hzYn
  // const cntrQty = grid.getValue(idx, 'cntrQty')
  // const dgCertiCnt = grid.getValue(idx, 'dgCertiCnt')
  // const hzYn = grid.getValue(idx, 'hzYn')
  if (dgCertiCnt > -1) {
    return `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">DG Certi. (${dgCertiCnt})</span>`
  } else if (hzYn === 'Y') {
    return `${cntrQty.split(',').join('<br/>')}<br/><span class="color_blue2 font_11">HZ</span>`
  } else {
    return cntrQty.split(',').join('<br/>')
  }
}
// 경과일 (BL 단위)
const rendererBlPodEta = (grid, cell) => {
  const idx = cell.index.dataRow
  const blPodEta = vmAppIm.gridList[idx].blPodEta
  // const blPodEta = grid.getValue(idx, 'blPodEta')
  return app.$ekmtcCommon.getDateDiffWithToday(blPodEta)
}

const rendererCntrNo = (grid, cell) => {
  const idx = cell.index.dataRow
  const cntrList = vmAppIm.gridList[idx].cntrList || []
  // const cntrList = grid.getValue(idx, 'cntrList') || []
  if (cntrList.length === 0) {
    return ''
  } else {
    if (cntrList.length > 3) {
      const h = 60 + ((cntrList.length - 3) * 20)
      grid.setRowHeight(idx, h, true)
    }
    const lastIndex = cntrList.length - 1
    return cntrList.map((vo, i) => {
      // 배열 마지막 data에 개행문자 \n을 붙이지 않음
      return '<p>' + vo.cntrNo + ((i === lastIndex) ? '</p>' : '\n</p>')
    }).join('')
  }
}

const rendererCntrDet = (grid, cell, demDetCd) => {
  const idx = cell.index.dataRow
  const cntrList = vmAppIm.gridList[idx].cntrList || []
  const blNo = vmAppIm.gridList[idx].blNo
  const bkgNo = ''
  if (cntrList.length === 0) {
    return ''
  } else {
    return '<button typwe="button" class="button sm request" onclick="vmAppIm.$refs.commonUnsolved.fnDemInvoiceMultiPop(event, \'' + bkgNo + '\', \'' + blNo + '\', \'I\', \'' + demDetCd + '\')">' + app.$t('msg.ONEX010T010.143') + '</button>' // 금액확인
  }
}

const rendererCntrDt = (grid, cell) => {
  const idx = cell.index.dataRow
  const cntrList = vmAppIm.gridList[idx].cntrList || []
  // const cntrList = grid.getValue(idx, 'cntrList') || []
  if (cntrList.length === 0) {
    return ''
  } else {
    const lastIndex = cntrList.length - 1
    return cntrList.map((vo, i) => {
      // 경과일이 없는건은 0으로 표시. // 배열 마지막 data에 개행문자 \n을 붙이지 않음
      return '<p>' + ((vo.strDt || '00000000') === '00000000' ? '0' : app.$ekmtcCommon.getDateDiffWithToday(vo.strDt)) + ((i === lastIndex) ? '</p>' : '\n</p>')
    }).join('')
  }
}

const fields = [
  { fieldName: 'bkgNo', dataType: 'text' },
  { fieldName: 'bkgStsCd', dataType: 'text' },
  { fieldName: 'srRno', dataType: 'text' },
  { fieldName: 'blNo', dataType: 'text' },
  { fieldName: 'blStsCd', dataType: 'text' },
  { fieldName: 'caStsCd', dataType: 'text' },
  { fieldName: 'shprRspsYn', dataType: 'text' },
  { fieldName: 'shprRspsBtnYn', dataType: 'text' },
  { fieldName: 'mtyPkupUsagYn', dataType: 'text' },
  { fieldName: 'shipper', dataType: 'text' },
  { fieldName: 'cgoModeCd', dataType: 'text' },
  { fieldName: 'por', dataType: 'text' },
  { fieldName: 'dly', dataType: 'text' },
  { fieldName: 'etd', dataType: 'text' },
  { fieldName: 'eta', dataType: 'text' },
  { fieldName: 'statusCd', dataType: 'text' },
  { fieldName: 'polCtrCd', dataType: 'text' },
  { fieldName: 'polPortCd', dataType: 'text' },
  { fieldName: 'polPortNm', dataType: 'text' },
  { fieldName: 'podCtrCd', dataType: 'text' },
  { fieldName: 'podPortCd', dataType: 'text' },
  { fieldName: 'podPortNm', dataType: 'text' },
  { fieldName: 'vslCd', dataType: 'text' },
  { fieldName: 'vslNm', dataType: 'text' },
  { fieldName: 'voyNo', dataType: 'text' },
  { fieldName: 'cntrQty', dataType: 'text' },
  { fieldName: 'dgCertiCnt', dataType: 'Number' },
  { fieldName: 'srYn', dataType: 'text' },
  { fieldName: 'docDtm', dataType: 'text' },
  { fieldName: 'cntrCntChk', dataType: 'text' },
  { fieldName: 'vgmStatus', dataType: 'text' },
  { fieldName: 'blCntrQty', dataType: 'Number' },
  { fieldName: 'shprCntrQty', dataType: 'Number' },
  { fieldName: 'bkgAcpnMethCd', dataType: 'text' },
  { fieldName: 'issueReason', dataType: 'text' },
  { fieldName: 'issueKind', dataType: 'text' },
  { fieldName: 'issueDate', dataType: 'text' },
  { fieldName: 'elYn', dataType: 'text' },
  { fieldName: 'frtYn', dataType: 'text' },
  { fieldName: 'cgoTypCd', dataType: 'text' },
  { fieldName: 'hzYn', dataType: 'text' },
  { fieldName: 'rvsdEndpDtm', dataType: 'text' },
  { fieldName: 'rvsdEndpYn', dataType: 'text' },
  { fieldName: 'msnNo', dataType: 'text' },
  { fieldName: 'cnclRsnRmk', dataType: 'text' },
  { fieldName: 'cnclRsnCd', dataType: 'text' },
  { fieldName: 'cnclRsnDsc', dataType: 'text' },
  { fieldName: 'anChk', dataType: 'text' },
  { fieldName: 'tel', dataType: 'text' },
  { fieldName: 'reqDtm', dataType: 'text' },
  { fieldName: 'tempData', dataType: 'text' },
  { fieldName: 'amtUsd', dataType: 'Number' },
  { fieldName: 'amtLocal', dataType: 'Number' },
  { fieldName: 'blPodEta', dataType: 'text' },
  { fieldName: 'diffDayCnt', dataType: 'Number' },
  { fieldName: 'blTypCd', dataType: 'text' },
  { fieldName: 'cgoClt', dataType: 'text' },
  { fieldName: 'cntrList', dataType: 'Array' },
  { fieldName: 'cyEnm', dataType: 'text' },
  { fieldName: 'trmlUrl', dataType: 'text' },
  { fieldName: 'inspecCatCd', dataType: 'text' },
  { fieldName: 'inspecCatNm', dataType: 'text' },
  { fieldName: 'doIssDt', dataType: 'text' },
  { fieldName: 'oblWthdExpcDt', dataType: 'text' },
  { fieldName: 'fstEntDtm', dataType: 'text' }
]

// 공통
const defCol = [
  {
    name: 'blNo',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONEX010T010.046') + '\n(' + app.$t('msg.ONEX010T010.093') + ')' }, // B/L No.\n(Status)
    width: '85',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo
        const blStsCd = vmAppIm.gridList[idx].blStsCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const blStsCd = grid.getValue(idx, 'blStsCd')

        // 부킹 승인 전 또는 S/R Create 전 일 때 (공란)
        if (blNo === null || blNo === undefined || blNo === '') {
          return ''
        }

        const html = []
        html.push(blNo)

        if (blStsCd === '01') {
          html.push('<span class="label2 blue">SUR</span>') // SUR
        } else if (blStsCd === '02') {
          html.push('<span class="label2 blue">WAY</span>') // WAY
        } else if (blStsCd === '03') {
          html.push('<span class="label2 blue">O.B/L</span>') // O.B/L
        } else if (blStsCd === '04') {
          html.push(`<span class="label2 blue">${app.$t('msg.ONIM050G010.011')}</span>`) // O.B/L 접수
        } else if (blStsCd === '05') {
          // html.push(`<span class="label2 blue">${app.$t('msg.ONIM050G010.011')}</span>`) // L/G 접수
          html.push('<span class="label2 blue">L/G 접수</span>') // L/G 접수
        } else if (blStsCd === '06') {
          // html.push(`<span class="label2 blue">${app.$t('msg.ONIM050G010.011')}</span>`) // D/O 발행 가능
          html.push('<span class="label2 blue">D/O 발행 가능</span>') // D/O 발행 가능
        } else if (blStsCd === '07') {
          html.push(`<span class="label2 red">${app.$t('msg.ONEX070G040.065')}</span>`) // 미발행
        } else {
          html.push(`<span class="label2 red">${app.$t('msg.CSBK100.022')}</span>`) // 미승인
        }
        return html.join('<br/>')
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'actShprCstEnm',
    fieldName: 'shipper',
    header: { text: app.$t('msg.VOSD100_M5.112') },
    width: '100',
    editable: false,
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'polPortCd',
    fieldName: 'etd',
    header: { text: app.$t('msg.ONEX010T010.049') }, // 출발지 ETD
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const por = vmAppIm.gridList[idx].por
        const etd = vmAppIm.gridList[idx].etd
        // const por = grid.getValue(idx, 'por')
        // const etd = grid.getValue(idx, 'etd')
        const etdText = convertDate(etd)
        return `${por} <br><span class="font_11">${etdText}</span>`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'podPortCd',
    fieldName: 'eta',
    header: { text: app.$t('msg.ONEX010T010.050') }, // 도착지 ETA
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const dly = vmAppIm.gridList[idx].dly
        const eta = vmAppIm.gridList[idx].eta || ''
        // const dly = grid.getValue(idx, 'dly')
        // const eta = grid.getValue(idx, 'eta') || ''
        const etaText = convertDate(eta)
        return `${dly} <br><span class="font_11">${etaText}</span>`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'vslNm',
    fieldName: 'vslNm',
    header: { text: app.$t('msg.ONEX010T010.051') },
    width: '100',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const vslNm = vmAppIm.gridList[idx].vslNm
        const voyNo = vmAppIm.gridList[idx].voyNo
        // const vslNm = grid.getValue(idx, 'vslNm')
        // const voyNo = grid.getValue(idx, 'voyNo')
        return `${vslNm}<br>${voyNo}`
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrQty',
    fieldName: 'cntrQty',
    header: { text: app.$t('msg.ONEX010T010.052') }, // Container
    width: '80',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrQty(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columnTyp01 = [
  {
    name: 'trmlEnm',
    fieldName: 'cyEnm',
    header: { text: app.$t('msg.ONEX010T010.144') }, // 접안부두(입항 터미널)
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const trmlEnm = vmAppIm.gridList[idx].cyEnm
        const trmlUrl = vmAppIm.gridList[idx].trmlUrl
        // const trmlEnm = grid.getValue(idx, 'cyEnm')
        // const trmlUrl = grid.getValue(idx, 'trmlUrl')
        if (trmlUrl !== undefined) {
          return `<a href="${trmlUrl}" target="_blank">${trmlEnm}</a>`
        } else {
          return trmlEnm
        }
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'inspecCatNm',
    fieldName: 'inspecCatNm',
    header: { text: app.$t('msg.ONEX010T010.086') }, // 관리대상
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo
        const inspecCatCd = vmAppIm.gridList[idx].inspecCatCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const inspecCatCd = grid.getValue(idx, 'inspecCatCd')
        const inspecCatNm = vmAppIm.getCodeNm('01277', inspecCatCd)
        if (inspecCatCd !== undefined) {
          return `<a href="#" onclick="vmAppIm.fnInsPecPop('${blNo}');">${inspecCatNm}</a>`
        } else {
          return 'N'
        }
      }
    }
  },
  {
    name: 'amtUsd',
    fieldName: 'amtUsd',
    header: { text: app.$t('msg.ONGO080G010.008') }, // 상세
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo
        const amtUsd = vmAppIm.gridList[idx].amtUsd
        const amtLocal = vmAppIm.gridList[idx].amtLocal
        const frtPncCd = vmAppIm.gridList[idx].frtPncCd
        const authImInv = vmAppIm.gridList[idx].authImInv
        const localCurCd = vmAppIm.gridList[idx].locCurCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const amtUsd = grid.getValue(idx, 'amtUsd')
        // const amtLocal = grid.getValue(idx, 'amtLocal')

        let txtAmtUsd = vmAppIm.$ekmtcCommon.changeNumberFormat('' + amtUsd, { isComma: true })
        let txtAmtLocal = vmAppIm.$ekmtcCommon.changeNumberFormat('' + amtLocal, { isComma: true })

        if (authImInv !== 'A' && frtPncCd === 'C') {
          txtAmtUsd = '**'
          txtAmtLocal = '**'
        } else {
          txtAmtUsd = vmAppIm.$ekmtcCommon.changeNumberFormat('' + amtUsd, { isComma: true })
          txtAmtLocal = vmAppIm.$ekmtcCommon.changeNumberFormat('' + amtLocal, { isComma: true })
        }

        if (amtUsd === 0 && txtAmtLocal === 0) {
          return `<a class="link" onclick="vmAppIm.fnFrtRequest(event, '${blNo}');">${app.$t('msg.ONGO080G010.026')}</a>`
        } else {
          return `USD ${txtAmtUsd}<br/> ${localCurCd + ' ' + txtAmtLocal}` //KRW ${txtAmtLocal}`
        }
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'blPodEta',
    fieldName: 'diffDayCnt',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false
  }
]

const columnTyp02 = [
  {
    name: 'trmlEnm',
    fieldName: 'cyEnm',
    header: { text: app.$t('msg.ONEX010T010.144') }, // 접안부두(입항 터미널)
    width: '90',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const trmlEnm = vmAppIm.gridList[idx].cyEnm
        const trmlUrl = vmAppIm.gridList[idx].trmlUrl
        // const trmlEnm = grid.getValue(idx, 'cyEnm')
        // const trmlUrl = grid.getValue(idx, 'trmlUrl')
        if (trmlUrl !== undefined) {
          return `<a href="${trmlUrl}" target="_blank">${trmlEnm}</a>`
        } else {
          return trmlEnm
        }
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'inspecCatNm',
    fieldName: 'inspecCatNm',
    header: { text: app.$t('msg.ONEX010T010.086') }, // 관리대상
    width: '90',
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo
        const inspecCatCd = vmAppIm.gridList[idx].inspecCatCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const inspecCatCd = grid.getValue(idx, 'inspecCatCd')
        const inspecCatNm = vmAppIm.getCodeNm('01277', inspecCatCd)
        if (inspecCatCd !== undefined) {
          return `<a href="#" onclick="vmAppIm.fnInsPecPop('${blNo}');">${inspecCatNm}</a>`
        } else {
          return 'N'
        }
      }
    }
  },
  // {
  //   name: 'unissueType',
  //   fieldName: 'blNo',
  //   header: { text: app.$t('msg.ONGO080G010.011') }, // 구분
  //   width: '70',
  //   editable: false,
  //   sortable: false,
  //   renderer: {
  //     type: 'html',
  //     callback (grid, cell, w, h) {
  //       const idx = cell.index.dataRow
  //       const blNo = vmAppIm.gridList[idx].blNo || ''
  //       // const blNo = grid.getValue(idx, 'blNo') || ''
  //       return `<a href="#" onclick="vmAppIm.fnDoIssue(event, '${blNo}')">${app.$t('msg.ONGO080G010.002')}</a>` // 미발행
  //     }
  //   }
  // },
  {
    name: 'blPodEta',
    fieldName: 'diffDayCnt',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false
  }
]

const columnTyp03 = [
  {
    name: 'trmlEnm',
    fieldName: 'cyEnm',
    header: { text: app.$t('msg.ONEX010T010.144') }, // 접안부두(입항 터미널)
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const trmlEnm = vmAppIm.gridList[idx].cyEnm
        const trmlUrl = vmAppIm.gridList[idx].trmlUrl
        // const trmlEnm = grid.getValue(idx, 'cyEnm')
        // const trmlUrl = grid.getValue(idx, 'trmlUrl')
        if (trmlUrl !== undefined) {
          return `<a href="${trmlUrl}" target="_blank">${trmlEnm}</a>`
        } else {
          return trmlEnm
        }
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'inspecCatNm',
    fieldName: 'inspecCatNm',
    header: { text: app.$t('msg.ONEX010T010.086') }, // 관리대상
    width: '90',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const blNo = vmAppIm.gridList[idx].blNo
        const inspecCatCd = vmAppIm.gridList[idx].inspecCatCd
        // const blNo = grid.getValue(idx, 'blNo')
        // const inspecCatCd = grid.getValue(idx, 'inspecCatCd')
        const inspecCatNm = vmAppIm.getCodeNm('01277', inspecCatCd)
        if (inspecCatCd !== undefined) {
          return `<a href="#" onclick="vmAppIm.fnInsPecPop('${blNo}');">${inspecCatNm}</a>`
        } else {
          return 'N'
        }
      }
    }
  },
  {
    name: 'unrecoveryType',
    fieldName: 'blNo',
    header: { text: app.$t('msg.ONGO080G010.011') }, // 구분
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return app.$t('msg.ONGO080G010.027') // 미회수
      }
    }
  },
  {
    name: 'doIssDt',
    fieldName: 'doIssDt',
    header: { text: app.$t('msg.ONGO080G010.029') }, // D/O 발행일
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const doIssDt = vmAppIm.gridList[idx].doIssDt || ''
        // const doIssDt = grid.getValue(idx, 'doIssDt') || ''
        return convertDateYmd(doIssDt)
      }
    }
  },
  {
    name: 'oblWthdExpcDt',
    fieldName: 'oblWthdExpcDt',
    header: { text: app.$t('msg.ONGO080G010.030') }, // 회수예정일\n(O.B/L)
    width: '70',
    editable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const oblWthdExpcDt = vmAppIm.gridList[idx].oblWthdExpcDt || ''
        // const oblWthdExpcDt = grid.getValue(idx, 'oblWthdExpcDt') || ''
        return convertDateYmd(oblWthdExpcDt)
      }
    }
  }
]

const columnTyp04 = [
  {
    name: 'cntrList01',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.013') }, // 컨테이너
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrNo(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrList02',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.032') }, // DEM 상세
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDet(grid, cell, '01')
      }
    }
  },
  {
    name: 'cntrList03',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        const idx = cell.index.dataRow
        const eta = vmAppIm.gridList[idx].blPodEta || ''
        return rendererCntrDt(grid, cell, eta)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columnTyp05 = [
  {
    name: 'cntrList01',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.013') }, // 컨테이너
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrNo(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  },
  {
    name: 'cntrList02',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.014') }, // DET 상세
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDet(grid, cell, '02')
      }
    }
  },
  {
    name: 'cntrList03',
    fieldName: 'cntrList',
    header: { text: app.$t('msg.ONGO080G010.009') }, // 경과일
    width: '70',
    editable: false,
    sortable: false,
    renderer: {
      type: 'html',
      callback (grid, cell, w, h) {
        return rendererCntrDt(grid, cell)
      }
    },
    exportStyleName: 'rg-export-excel-cell'
  }
]

const columns1 = [
  ...defCol,
  ...columnTyp01
]

const columns2 = [
  ...defCol,
  ...columnTyp02
]

const columns3 = [
  ...defCol,
  ...columnTyp03
]

const columns4 = [
  ...defCol,
  ...columnTyp04
]

const columns5 = [
  ...defCol,
  ...columnTyp05
]

const defLayer = [
  'blNo',
  'actShprCstEnm',
  'polPortCd',
  'podPortCd',
  'vslNm',
  'cntrQty'
]

const layerTyp01 = [
  'trmlEnm',
  'inspecCatNm',
  {
    name: 'typ01',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.015') }, // '미정산/미청구 현황'
    items: [
      'amtUsd',
      'blPodEta'
    ]
  }
]

const layoutTyp01 = [
  ...defLayer,
  ...layerTyp01
]

const layerTyp02 = [
  'trmlEnm',
  'inspecCatNm',
  {
    name: 'typ02',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.016') }, // 미발행 현황
    items: [
      // 'unissueType',
      'blPodEta'
    ]
  }
]

const layoutTyp02 = [
  ...defLayer,
  ...layerTyp02
]

const layerTyp03 = [
  'trmlEnm',
  'inspecCatNm',
  {
    name: 'typ03',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.028') }, // O.B/L 미회수 현황
    items: [
      'unrecoveryType',
      'doIssDt',
      'oblWthdExpcDt'
    ]
  }
]

const layoutTyp03 = [
  ...defLayer,
  ...layerTyp03
]

const layerTyp04 = [
  {
    name: 'typ04',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.033') }, // 미반출 현황
    items: [
      'cntrList01',
      // 'cntrList02',
      'cntrList03'
    ]
  }
]

const layoutTyp04 = [
  ...defLayer,
  ...layerTyp04
]

const layerTyp05 = [
  {
    name: 'typ05',
    direction: 'horizontal',
    header: { text: app.$t('msg.ONGO080G010.035') }, // 미반입 현황
    items: [
      'cntrList01',
      // 'cntrList02',
      'cntrList03'
    ]
  }
]

const layoutTyp05 = [
  ...defLayer,
  ...layerTyp05
]

export default {
  convertDate,
  rendererCntrQty,
  rendererBlPodEta,
  columns1,
  layoutTyp01,
  columns2,
  layoutTyp02,
  columns3,
  layoutTyp03,
  columns4,
  layoutTyp04,
  columns5,
  layoutTyp05,
  fields
}
